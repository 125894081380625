import { FC, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import * as utils from '../../utils';
import DetailContext from '../../store/detailContext';
import './detailModal.scss';
import { ShareButton } from '../ShareButton';

export const DetailModal: FC<{}> = ({ children }) => {
  const { show, handleClose, modalContent } = useContext(DetailContext);

  return (
    <Modal show={show} onHide={handleClose}>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={handleClose}
      ></button>

      <article className="row project">
        <div className="col-12">
          <h2 className="title">{modalContent?.project_name}</h2>

          <p className="assignee">Zodpovědný: {modalContent?.councilor_name}</p>
        </div>

        <div className="col-12 tags-list">
          {modalContent?.hashtags?.map((hashtag, index) => (
            <button className="tag" key={index}>
              {hashtag}
            </button>
          ))}
        </div>
        <div className="modal-stats-wrapper">
          <div className="date-wrapper">
            <span className="date">{utils.formatDate(modalContent?.project_start)}</span>
            <strong className="date-description">Projekt {utils.isInFuture(modalContent?.project_start) ? 'začne' : 'začal'}</strong>
          </div>
          <div className="date-wrapper">
            <span className="date">{utils.formatDate(modalContent?.project_end)}</span>
            <strong className="date-description">Projekt {utils.isInFuture(modalContent?.project_end) ? 'skončí' : 'skončil'}</strong>
          </div>

          <div className="percents pie-chart">
            <div
              className={`chart ${(modalContent?.pragozor_percentage || 0) > 50 ? 'gt-50' : ''}`}
            >
              <div className="ppc-progress">
                <div
                  className="ppc-progress-fill"
                  style={{
                    transform: `rotate(${
                      (360 * (modalContent?.pragozor_percentage || 0)) / 100
                    }deg)`,
                  }}
                ></div>
              </div>
              <div className="ppc-percents">
                <div className="pcc-percents-wrapper">{modalContent?.pragozor_percentage} %</div>
              </div>
            </div>
          </div>
        </div>

        <div id="modal-body" className="col-12 py-2 py-md-5">
          {modalContent?.pragozor_text}
        </div>

        <div className="col-12 share">
          <p>
            <ShareButton projectId={modalContent?.id} />
          </p>
        </div>
      </article>
    </Modal>
  );
};
