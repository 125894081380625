interface ISubCategory {
  tag: string;
  name: string;
}

export interface ICategory {
  name: string;
  subCategories: ISubCategory[];
}

export const categories: { [key: string]: ICategory } = {
  "Digitalizace": {
    name: "Digitalizace",
    subCategories: [
      {
          tag: "SmartCity",
          name: "SmartCity",
      },
      {
          tag: "ModerniUrad",
          name: "Moderní úřad",
      },
      {
          tag: "Infrastruktura",
          name: "Infrastruktura",
      },
    ],
  },
  "Bydleni": {
    name: "Bydlení",
    subCategories: [],
  },
  "Doprava": {
    name: "Doprava",
    subCategories: [
      {
          tag: "Cyklodoprava",
          name: "Cyklodoprava",
      },
      {
          tag: "Zeleznice",
          name: "Železnice",
      },
      {
          tag: "ModerniDoprava",
          name: "Moderní doprava",
      },
      {
          tag: "TramvajoveTrate",
          name: "Tramvajové tratě",
      },
      {
          tag: "Okruh",
          name: "Okruh",
      },
      {
          tag: "PR",
          name: "P+R",
      },
      {
          tag: "Mosty",
          name: "Mosty",
      },
    ],
  },
  "UzemniRozvoj": {
    name: "Územní rozvoj",
    subCategories: [
      {
          tag: "VyznamneBudovy",
          name: "Významné budovy",
      },
      {
          tag: "MestskeParky",
          name: "Městské parky",
      },
      {
          tag: "UlicneProstranstvi",
          name: "Uličné prostranství",
      },
      {
          tag: "TransformaceBrownfieldu",
          name: "Transformace brownfieldů",
      },
      {
          tag: "RozvojLokality",
          name: "Rozvoj lokality",
      },
      {
          tag: "RozvojKrajiny",
          name: "Rozvoj krajiny",
      },
    ],
  },
  "Bezpecnost": {
    name: "Bezpečnost",
    subCategories: [],
  },
  "RizeniUradu": {
    name: "Řízení úřadu",
    subCategories: [],
  },
  "ZivotniProstredi": {
    name: "Životní prostředí",
    subCategories: [],
  },
  "Kultura": {
    name: "Kultura",
    subCategories: [],
  },
  "SkolstviASport": {
    name: "Školství a sport",
    subCategories: [],
  },
  "SocialniPece": {
    name: "Sociální péče",
    subCategories: [],
  },
  "Zdravotnictvi": {
    name: "Zdravotnictví",
    subCategories: [],
  },
  "Inovace": {
    name: "Věda a inovace",
    subCategories: [],
  },
};
