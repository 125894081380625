import { FC } from 'react';
import { Link } from 'react-router-dom';
import './categoryButton.scss';

export const CategoryButton: FC<{ tag: string; projectName: string; }> = ({ children, tag, projectName }) => {
  return (
    <Link to={`/${tag}`} className="categoryButton" data-tag={tag} data-project-name={projectName}>
      <img
        className="categoryButton__icon"
        src={`/images/icons/${tag}.png`}
        alt={`button logo ${projectName}`}
      />
      <span>{projectName}</span>
    </Link>
  );
};
