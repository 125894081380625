import data from '../api/data-projekty.json';

export interface IProjectData {
  id: number;
  identifier: string;
  project_name: string;
  active: boolean;
  public: boolean;
  created_at: string | null;
  project_start: string | null;
  project_end: string | null;
  supplier_name: string | null;
  budget_expected: number | null;
  budget_reality: number | null;
  council_rule_number: string | null;
  contract_number: string | null;
  tender_number: string | null;
  project_description: string | null;
  status_explanation: string | null;
  council_rule_url: string | null;
  contract_registry_url: string | null;
  tender_url: string | null;
  hashtag: string | null;
  solver_name: string | null;
  solver_id: number | null;
  mhmp_responsible_id: number | null;
  mhmp_responsible_name: string | null;
  councilor_id: number | null;
  councilor_name: string | null;
  parent_id: number | null;
  status: string | null;
  parent_project: string | null;
  pragozor_percentage: number | null;
  pragozor_text: string | null;

  hashtags?: string[];
};

interface IProjectsResponse {
  data: IProjectData[];
  id: string;
  metadata: any;
}

export const getProjects = async (): Promise<IProjectData[]> => {
  try {
    const projects = (data[0] as IProjectsResponse).data;

    // filter data
    const filtered = projects.filter((p) => {

      if (p.hashtag === null || !p.public) {
        return false;
      }

      if (p.project_name.indexOf('MHMP') !== -1 || !p.active) {
        return false;
      }

      if (p.councilor_name === 'nope') {
        return false;
      }

      return true;

      // TODO

      // // Hide/Skip project which doesn't match subcategory ID
      // if (category_id.indexOf(p.id) === -1) {
      //   continue;
      // }

      // // Hide/Skip project which doesn't match main category ID
      // if (
      //   councilor_name === 'show_all_subcategories_projects' &&
      //   category_id.indexOf(p.parent_id) === -1
      // ) {
      //   continue;
      // }

      // // Hide/Skip project if tag isn't in hashtag field
      // if (
      //   councilor_name === 'show_by_tag' &&
      //   (p.hashtag === null || p.hashtag.indexOf(p.id) === -1)
      // ) {
      //   continue;
      // }

      // // Hide/Skip project which for specific councilor
      // if (
      //   councilor_name !== 'success' &&
      //   councilor_name !== p.councilor_name &&
      //   category_id === 'nope'
      // ) {
      //   continue;
      // }
    });

    // sort data
    const sorted = filtered.sort((a, b) =>
      (a.project_name || '').localeCompare(b.project_name || '')
    );

    // parse hashtags
    const parsed = sorted.map((project) => {
      const hashtags = project.hashtag?.split('#').map((hashtag) => hashtag.trim());
      if (hashtags && hashtags.length > 1) {
        return {
          ...project,
          hashtags: hashtags.slice(1),
        }
      }
      return project;
    })


    return parsed;
  } catch (error: any) {
    throw error;
  }
}
