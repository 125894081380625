import { FC, useContext } from 'react';
import AppContext from '../../store/appContext';
import './sortSelect.scss';

export const SortByEnum: { [key: string]: string } = {
  nameAsc: `Název \u2191`,
  nameDesc: 'Název \u2193',
  percentageAsc: 'Splněno \u2191',
  percentageDesc: 'Splněno \u2193',
};

export const SortSelect: FC<{}> = ({ children }) => {
  const { sortProjects } = useContext(AppContext);

  return (
    <div className="position-relative select-wrapper">
      <select
        className="sortSelect"
        name="sortBy"
        defaultValue={"nameAsc"}
        onChange={(e) => sortProjects(e.target.value)}
      >
        {Object.keys(SortByEnum).map((key) => (
          <option key={key} value={key}>
            {SortByEnum[key]}
          </option>
        ))}
      </select>
      <img className="sortSelect__select-chevron" src="/images/chevron-down.svg" alt="v" />
    </div>
  );
};
