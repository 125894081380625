import React from 'react';
import { Router } from './components/router';
import { BrowserRouter } from 'react-router-dom';

// if you need to customize bootstrap
import './styles/custom.scss';

import { Layout } from './components/Layout';
import { DetailModal } from './components/DetailModal';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <DetailModal />
      <Layout>
        <Router />
      </Layout>
    </BrowserRouter>
  );
};

export default App;
