import { FC, useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CategoryButton } from '../../components/CategoryButton';
import { Title } from '../../components/Title';
import { categories } from '../../api/categories';
import { ProjectList } from '../../components/ProjectList';
import { CouncilorSelect } from '../../components/CouncilorSelect';
import { useLocation } from 'react-router-dom';
import AppContext from '../../store/appContext';
import DetailContext from '../../store/detailContext';
import { SortSelect } from '../../components/SortSelect';

export const HomeView: FC = () => {
  const { search } = useLocation();
  const { setCurrentHastag, allProjects } = useContext(AppContext);
  const { handleShow } = useContext(DetailContext);
  const searchParams = new URLSearchParams(search);
  const hashtag = searchParams.get('hashtag');
  const projectId = searchParams.get('projectId');

  useEffect(() => {
    if (hashtag) {
      setCurrentHastag(hashtag);
    } else {
      setCurrentHastag(undefined);
    }
  }, [hashtag, setCurrentHastag]);

  useEffect(() => {
    if (projectId) {
      const projectData = allProjects?.find((p) => p.id === parseInt(projectId, 10));
      if (projectData) {
        handleShow(projectData, true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, allProjects]);

  return (
    <Title title={null}>
      <section className="layout__description background">
        <Container>
          <Row className="row content">
            <Col className="col-12 text-center text-md-start">
              <h1 className="layout__h1">
                Projekty <span>v Praze</span>
              </h1>

              <p className="layout__projects-total">
                Aktuálně probíhá na území Prahy více než 500 klíčových projektů, na které dohlížíme.
                Níže si můžete některé z nich prohlédnout.<br/>
                <strong>Seznam a stavy projektů nejsou od roku 2023 již aktualizovány.</strong>
              </p>
            </Col>

            <Col className="layout__main-categories">
              <div className="layout__categories-with-icons">
                {Object.keys(categories).map((tag, index) => (
                  <CategoryButton key={index} tag={tag} projectName={categories[tag].name} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="layout__data container">
        <div className="layout__data-title__wrapper">
          <h2 className="text-center text-md-start page-headline">Všechny projekty</h2>
          <div className="layout__data-selects">
            <CouncilorSelect />
            <SortSelect />
          </div>
        </div>

        <div className="container">
          <ProjectList category={null} hashtag={hashtag} />
        </div>
      </section>
    </Title>
  );
};
