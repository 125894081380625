import { FC } from 'react';
import './loadingSpinner.scss';

export const LoadingSpinner: FC<{}> = ({ children }) => {
  return (
    <div className="global-loader">
      <img alt="loading" className="circle" src="images/loading.svg" />
      <img alt="loading-logo" className="loading-logo" src="images/loading-logo.svg" />
    </div>
  );
};
