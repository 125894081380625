import { FC, useContext } from 'react';
import AppContext from '../../store/appContext';
import './councilorSelect.scss';

export const CouncilorSelect: FC<{}> = ({ children }) => {
  const { councilors, filterByCouncilor } = useContext(AppContext);

  return (
    <div className="position-relative select-wrapper">
      <select className="councilorSelect" name="assignee" defaultValue={""} onChange={e => filterByCouncilor(e.target.value)}>
        <option value="">Zodpovědný</option>
        {councilors?.map((name, index) => (
          <option key={index} value={name}>
            {name}
          </option>
        ))}
      </select>
      <img className="councilorSelect__select-chevron" src="/images/chevron-down.svg" alt="v" />
    </div>
  );
};
