import { FC, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import 'vanilla-cookieconsent';
import './cookieConsent.scss';
import { config } from '../../configuration';

export const CookieConsent: FC = () => {
  let cc = useRef<any>(null);

  useEffect(() => {
    const win = window as Window &
      typeof globalThis & { initCookieConsent: () => any; CookieConsent: any };

    // ./assets/js/cookieconsent.js has to be load before
    if (!win.initCookieConsent) {
      return;
    }
    cc.current = win.initCookieConsent();
    win.CookieConsent = cc.current;

    if (config.GA_TOKEN) {
      // ga init and disable
      ReactGA.initialize(
        [
          {
            trackingId: config.GA_TOKEN,
            gaOptions: {
              name: 'tracker1',
              userId: 'accept',
            },
          },
          {
            trackingId: config.GA_TOKEN_COOKIELESS || '',
            gaOptions: {
              name: 'tracker2',
              storage: 'none',
              cookieName: 'pragozorcz-cookieconsentcookieless',
            },
          },
        ],
        { alwaysSendToDefaultTracker: false }
      );
      //ReactGA.set({ anonymizeIp: true }, ["tracker2"]);
      (win as any)[`ga-disable-${config.GA_TOKEN}`] = true;
    }

    cc.current.run({
      current_lang: 'cz',
      cookie_name: 'pragozorcz-cookieconsent', // default: 'cc_cookie'
      force_consent: false,

      gui_options: {
        consent_modal: {
          layout: 'bar', // box,cloud,bar
          position: 'bottom center', // bottom,middle,top + left,right,center
          transition: 'slide', // zoom,slide
        },
        settings_modal: {
          layout: 'box', // box,bar
          // position: 'left',                // right,left (available only if bar layout selected)
          transition: 'slide', // zoom,slide
        },
      },

      onAccept: (cookie: any) => {
        for (const level of cookie.level) {
          // analytics category
          if (level === 'analytics' && config.GA_TOKEN) {
            // ga enable
            // docs https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
            (win as any)[`ga-disable-${config.GA_TOKEN}`] = false;
          }
        }
      },

      onChange: (cookie: any, changed_preferences: any) => {
        // If analytics category's status was changed
        if (changed_preferences.indexOf('analytics') > -1) {
          // If analytics category is disabled
          if (!cc.current.allowedCategory('analytics')) {
            // Disable ga
            (win as any)[`ga-disable-${config.GA_TOKEN}`] = true;
          } else {
            // Enable ga
            (win as any)[`ga-disable-${config.GA_TOKEN}`] = false;
          }
        }
      },

      languages: {
        cs: {
          consent_modal: {
            title: 'Používáme cookies',
            description:
              'Díky využití souborů cookies zjišťujeme, co je pro uživatele zajímavé. Analýza návštěvnosti nám pomáhá web neustále zlepšovat. Nepoužíváme cookies k marketingovým účelům, ani je nepředáváme nikomu dalšímu. <br><br> Dovolíte nám je takto používat?',
            primary_btn: {
              text: 'Povolit vše',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Nastavení cookies',
              role: 'c-settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Přizpůsobit nastavení cookies',
            save_settings_btn: 'Uložit nastavení',
            accept_all_btn: 'Povolit vše',
            reject_all_btn: 'Povolit nezbytné',
            close_btn_label: 'Zavřít',
            blocks: [
              {
                title: 'Nezbytně nutné cookies',
                description:
                  'Tyto cookies pomáhají, aby webová stránka byla použitelná a fungovala správně. Ve výchozím nastavení jsou povoleny a nelze je zakázat.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: 'Statistika',
                description:
                  'Díky těmto cookies víme, kam u nás chodíte nejraději a máme statistiky o celkové návštěvnosti našich stránek.',
                toggle: {
                  value: 'analytics', // there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
      },
    });
  }, []);

  return (
    <button
      className="link"
      onClick={() => cc.current && cc.current.showSettings()}
      data-cc="c-settings"
    >
      Cookies
    </button>
  );
};
