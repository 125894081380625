import { createContext, useState, Dispatch, SetStateAction, useCallback } from 'react';
import { IProjectData, getProjects as getProjectsAPI } from '../api/projects';

const COUNCILORS_SKIP = ['Tomáš Sladký'];
const PAGE_SIZE = 25;

export interface IChildren {
  children?: React.ReactNode;
}

interface IAppCtx {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
  getProjects: (category: string | null, hashtag: string | null) => Promise<void>;
  projects: IProjectData[] | undefined;
  setProjects: Dispatch<SetStateAction<IProjectData[] | undefined>>;
  allProjects: IProjectData[] | undefined;
  councilors: string[] | undefined;
  setCouncilors: Dispatch<SetStateAction<string[] | undefined>>;
  currentHastag: string | undefined;
  setCurrentHastag: Dispatch<SetStateAction<string | undefined>>;
  filterByCouncilor: (name: string) => void;
  currentPage: number;
  paginate: (page: number) => void;
  showLoadMoreButton: boolean;
  sortBy: string;
  sortProjects: (sortBy: string) => void;
}

const AppContext = createContext<IAppCtx>({
  isLoading: false,
  setIsLoading: () => false,
  error: null,
  setError: () => null,
  getProjects: async () => {},
  projects: [],
  setProjects: () => {},
  allProjects: [],
  councilors: [],
  setCouncilors: () => {},
  currentHastag: undefined,
  setCurrentHastag: () => {},
  filterByCouncilor: () => {},
  currentPage: 1,
  paginate: () => {},
  showLoadMoreButton: false,
  sortBy: "nameAsc",
  sortProjects: () => {},
});

export const AppContextProvider = ({ children }: IChildren) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [projects, setProjects] = useState<IProjectData[] | undefined>();
  const [councilors, setCouncilors] = useState<string[] | undefined>();
  const [currentHastag, setCurrentHastag] = useState<string | undefined>();
  const [councilorFilter, setCouncilorFilter] = useState<string | undefined>();
  const [allProjects, setAllProjects] = useState<IProjectData[] | undefined>();
  const [currentPage, setPage] = useState<number>(1);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("nameAsc");

  const paginate = (page: number) => {
    setPage(page);
    const items = [
      ...(projects || []),
      ...(allProjects?.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE) || []),
    ];
    setProjects(items);
    if ((allProjects?.length || 0) >= page * PAGE_SIZE) {
      setShowLoadMoreButton(true);
    } else {
      setShowLoadMoreButton(false);
    }
  };

  const filterByCouncilor = (value: string) => {
    setCouncilorFilter(value);
    if (value) {
      let filtered = allProjects?.filter((p) => p.councilor_name === value);
      if (sortBy) {
        filtered = filtered?.sort(projectSortingFnc(sortBy));
      }
      setProjects(filtered);
      setShowLoadMoreButton(false);
    } else {
      let nonFiltered = allProjects;
      if (sortBy) {
        nonFiltered = nonFiltered?.sort(projectSortingFnc(sortBy));
      }
      setProjects(nonFiltered?.slice(0, PAGE_SIZE));
      if ((nonFiltered?.length || 0) > PAGE_SIZE) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }
    }
  };

  const sortProjects = (value: string) => {
    setSortBy(value);
    let sorted = allProjects?.sort(projectSortingFnc(value));
    if (councilorFilter) {
      sorted = sorted?.filter((p) => p.councilor_name === councilorFilter);
      setProjects(sorted);
      setShowLoadMoreButton(false);
    } else {
      setProjects(sorted?.slice(0, PAGE_SIZE));
      if ((allProjects?.length || 0) > PAGE_SIZE) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }
    }
  };

  const projectSortingFnc = (_sortBy: string) => (a: IProjectData, b: IProjectData) => {
    switch (_sortBy) {
      case "nameAsc":
        return (a.project_name || '').localeCompare(b.project_name || '');
      case "nameDesc":
        return (b.project_name || '').localeCompare(a.project_name || '');
      case "percentageAsc":
        return (a.pragozor_percentage || 0) === (b.pragozor_percentage || 0)
          ? (a.project_name || '').localeCompare(b.project_name || '')
          : (a.pragozor_percentage || 0) > (b.pragozor_percentage || 0)
          ? 1
          : -1;
      case "percentageDesc":
        return (a.pragozor_percentage || 0) === (b.pragozor_percentage || 0)
          ? (a.project_name || '').localeCompare(b.project_name || '')
          : (a.pragozor_percentage || 0) < (b.pragozor_percentage || 0)
          ? 1
          : -1;
      default:
        return (a.project_name || '').localeCompare(b.project_name || '');
    }
  };

  const getProjects = useCallback(async (category: string | null, hashtag: string | null) => {
    setIsLoading(true);
    setError(null);
    try {
      let projects = await getProjectsAPI();

      projects = projects.filter((p) => {
        if (p.hashtag === null || !p.public) {
          return false;
        }

        if (p.project_name.indexOf('MHMP') !== -1 || !p.active) {
          return false;
        }

        if (!hashtag && category && p.hashtags && p.hashtags.indexOf(category) === -1) {
          return false;
        }

        if (!category && hashtag && p.hashtags && p.hashtags.indexOf(hashtag) === -1) {
          return false;
        }

        return true;
      });

      projects = projects.sort(projectSortingFnc(sortBy));

      setProjects(projects.slice(0, PAGE_SIZE));
      setAllProjects(projects);
      if (projects.length > PAGE_SIZE) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }

      const councilorsSet = new Set<string>();
      for (const project of projects) {
        if (project.councilor_name && !COUNCILORS_SKIP.includes(project.councilor_name)) {
          councilorsSet.add(project.councilor_name);
        }
      }
      setCouncilors(Array.from(councilorsSet));
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // provide a value
  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading,
        error,
        setError,
        getProjects: getProjects,
        projects: projects,
        setProjects: setProjects,
        allProjects: allProjects,
        councilors: councilors,
        setCouncilors: setCouncilors,
        currentHastag: currentHastag,
        setCurrentHastag: setCurrentHastag,
        filterByCouncilor: filterByCouncilor,
        currentPage,
        paginate,
        showLoadMoreButton,
        sortBy,
        sortProjects,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
