export const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) {
    return '-';
  }
  const date = new Date(dateString);
  let day: number | string = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  let month: number | string = date.getUTCMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  return `${day}.${month}.${date.getFullYear()}`;
};

export const isInFuture = (dateString: string | null | undefined): boolean => {
  if (!dateString) {
    return true;
  }
  const date = new Date(dateString);
  const now = new Date();
  return date.getTime() > now.getTime();
};
