import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './store/appContext';
import { DetailContextProvider } from './store/detailContext';

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
        <DetailContextProvider>
          <App />
        </DetailContextProvider>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
