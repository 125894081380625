import { FC, useContext, useEffect, useRef } from 'react';
import AppContext from '../../store/appContext';
import { ProjectListItem } from '../ProjectListItem';

export const ProjectList: FC<{
  category: string | null;
  hashtag: string | null;
}> = ({ children, category, hashtag }) => {
  const { getProjects, projects, currentPage, paginate, showLoadMoreButton } = useContext(AppContext);
  const loader = useRef(null);

  const handleObserver = (entities: any) => {
    paginate(currentPage + 1);
  };

  useEffect(() => {
    getProjects(category, hashtag);
  }, [getProjects, category, hashtag]);

  return (
    <div id="projects-list">
      <div id="projects-list-data" className="col-12">
        {projects ? (
          projects.map((project, index) => <ProjectListItem key={index} projectData={project} />)
        ) : (
          <p>Žádný projekt</p>
        )}
        <div className="load-more">
          <button className="link" ref={loader} onClick={handleObserver} hidden={!showLoadMoreButton}>
            Načíst další
          </button>
        </div>
      </div>
    </div>
  );
};
