import { FC, useContext } from "react";
import AppContext from "../../store/appContext";
import { LoadingSpinner } from "../LoadingSpinner";
import { Footer } from "./footer";
import { Header } from "./header";

export const Layout: FC = ({ children }) => {
  const { isLoading } = useContext(AppContext);

  return (
    <div className="main-wrapper">
      <Header />
      <main className="main">{children}</main>
      {isLoading ? <LoadingSpinner /> : null }
      <Footer />
    </div>
  );
};
