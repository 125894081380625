// import { config } from "config";
import { FC } from 'react';
// for use with react-bootstrap
import { Container, Row, Col } from 'react-bootstrap';
import { CookieConsent } from '../CookieConsent';

export const Footer: FC = () => {
  return (
    <Container className="py-5">
      <Row>
        <Col className="col-12 text-center text-uppercase">
          <p className="mb-3">Operátor ICT, a.s. © Pragozor 2022</p>
          <CookieConsent />
        </Col>
      </Row>
    </Container>
  );
};
