import { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CouncilorSelect } from '../../components/CouncilorSelect';
import { SubCategoryButton } from '../../components/SubCategoryButton';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Title } from '../../components/Title';
import { ICategory } from '../../api/categories';
import { ProjectList } from '../../components/ProjectList';
import { SortSelect } from '../../components/SortSelect';

export const CategoryView: FC<{ tag: string; category: ICategory }> = ({ tag, category }) => {
  const { hash } = useLocation();

  const subCategoryTag = hash.slice(1);
  let subCategoryName: string | undefined;
  if (subCategoryTag) {
    for (const c of category.subCategories) {
      if (c.tag === subCategoryTag) {
        subCategoryName = c.name;
        break;
      }
    }
  }
  if (subCategoryTag && subCategoryTag !== tag && !subCategoryName) {
    return <Navigate to={`/${tag}`} />;
  }

  return (
    <Title title={subCategoryName || category.name}>
      <section className="layout__description background">
        <Container>
          <Row className="row content">
            <Col className="col-12 category__back-to-all-projects text-center text-md-start cursor-pointer">
              <Link to={'/'}>
                {' '}
                <span className="category__long-arrow-left"></span>Zpět na všechny projekty
              </Link>
            </Col>

            <Col className="col-12 text-center text-md-start">
              <h1 className="layout__h1">{subCategoryName || category.name}</h1>

              <p className="layout__projects-total">
                Aktuálně probíhá na území Prahy více než 500 klíčových projektů, na které dohlížíme.
                Níže si můžete některé z nich prohlédnout.<br/>
                <strong>Seznam a stavy projektů nejsou od roku 2023 již aktualizovány.</strong>
              </p>
            </Col>

            <Col className="category__sub-categories">
              <div className="category__categories-without-icons">
                <SubCategoryButton
                  key={0}
                  tag={tag}
                  projectName={category.name}
                  active={!subCategoryTag || subCategoryTag === tag ? true : false}
                />
                {category.subCategories.map((subcat, index) => (
                  <SubCategoryButton
                    key={index + 1}
                    tag={subcat.tag}
                    projectName={subcat.name}
                    active={subCategoryTag === subcat.tag}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="layout__data container">
        <div className="layout__data-title__wrapper">
          <h2 className="text-center text-md-start page-headline">Všechny projekty</h2>
          <div className="layout__data-selects">
            <CouncilorSelect />
            <SortSelect />
          </div>
        </div>

        <div className="container">
          <ProjectList category={subCategoryTag || tag} hashtag={null} />
        </div>
      </section>
    </Title>
  );
};
