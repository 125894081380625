import { FC } from "react";
// for use with react-bootstrap
import { Container, Nav } from 'react-bootstrap';
import { config } from '../../configuration';

export const Header: FC = () => {

  return (
    <Container>
        <Nav className="header__nav">
          <a href={config.PRAGOZOR_URL ? config.PRAGOZOR_URL : "https://pragozor.cz"} className="header__logo">
            <img alt="logo" src="/images/logo.svg" />
          </a>
          <div className="header__links">
            <a href={config.PRAGOZOR_URL ? config.PRAGOZOR_URL : "https://pragozor.cz"} className="header__backlink">
              Zpět na pragozor.cz
            </a>
          </div>
        </Nav>
    </Container>
  );
};
