import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Title: FC<{ title: string | null }> = ({ title, children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!title) {
      document.title = t('Projekty v Praze');
    } else {
      document.title = `${title} | ${t('Projekty v Praze')}`;
    }
  }, [title, t]);

  return <>{children}</>;
};
