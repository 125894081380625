import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './shareButton.scss';

export const ShareButton: FC<{ projectId: number | undefined }> = ({ children, projectId }) => {
  const [coppied, setCoppied] = useState<boolean>(false);
  const location = useLocation();

  const copyOnClipboard = async (id: number | undefined) => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.href
          .replace(location.pathname, '/')
          .replace(location.search, '')}?projectId=${id}`
      );
      setCoppied(true);
      setTimeout(() => {
        setCoppied(false);
      }, 3000);
    } catch (err) {
      console.error('unable to copy link');
    }
  };

  return (
    <>
      <button className="link" onClick={() => copyOnClipboard(projectId)}>
        <img src="https://pragozor.cz/assets/images/share.svg" title="Sdílet" alt="sdilet" /> Sdílet
      </button>
      {coppied ? <span className="coppied">Odkaz zkopírován</span> : null}
    </>
  );
};
