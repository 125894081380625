import { createContext, useState } from 'react';
import { createBrowserHistory } from 'history';
import { IProjectData } from '../api/projects';

export interface IChildren {
  children?: React.ReactNode;
}

interface IDetailCtx {
  show: boolean;
  modalContent: IProjectData | undefined;
  handleClose: () => void;
  handleShow: (content: IProjectData, handledByUrl?: boolean) => void;
}

const DetailContext = createContext<IDetailCtx>({
  show: false,
  modalContent: undefined,
  handleClose: () => {},
  handleShow: (content: IProjectData, handledByUrl?: boolean) => {},
});

export const DetailContextProvider = ({ children }: IChildren) => {
  const [show, setShow] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IProjectData | undefined>(undefined);
  const [handledByUrl, setHandledByUrl] = useState<boolean>(false);
  const history = createBrowserHistory();

  const handleClose = () => {
    if (handledByUrl) {
      history.replace({
        search: "",
      });
    }
    setModalContent(undefined);
    setShow(false);
  }

  const handleShow = (content: IProjectData, handledByUrl: boolean = false) => {
    setHandledByUrl(handledByUrl);
    setModalContent(content);
    setShow(true);
  }

  // provide a value
  return (
    <DetailContext.Provider
      value={{
        show,
        modalContent,
        handleClose,
        handleShow,
      }}
    >
      {children}
    </DetailContext.Provider>
  );
};

export default DetailContext;
