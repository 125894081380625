import { FC, useContext } from 'react';
import * as utils from '../../utils';
import { IProjectData } from '../../api/projects';
import './projectListItem.scss';
import { Link } from 'react-router-dom';
import AppContext from '../../store/appContext';
import DetailContext from '../../store/detailContext';
import { ShareButton } from '../ShareButton';

export const ProjectListItem: FC<{ projectData: IProjectData }> = ({ children, projectData }) => {
  const { currentHastag } = useContext(AppContext);
  const { handleShow } = useContext(DetailContext);

  return (
    <article id={`${projectData.id}`} className="projectListItem">
      <div className="tags-list">
        {projectData.hashtags?.map((hashtag, index) => (
          <Link to={hashtag !== currentHastag ? `/?hashtag=${hashtag}` : `/`} key={index} className={`tag cursor-pointer ${hashtag === currentHastag ? "active" : ""}`}>
            {hashtag}
          </Link>
        ))}
      </div>
      <div className="project-container">
        <div>
          <button className="title link" onClick={() => handleShow(projectData)}>{projectData.project_name}</button>
          <div className="assignee">Zodpovědný: {projectData.councilor_name}</div>
          <div className="share">
            <ShareButton projectId={projectData.id} />
          </div>
        </div>
        <div className="date-wrapper">
          <span className="date">{utils.formatDate(projectData.project_start)}</span>
          <strong className="date-description">Projekt {utils.isInFuture(projectData.project_start) ? 'začne' : 'začal'}</strong>
        </div>
        <div className="date-wrapper">
          <span className="date">{utils.formatDate(projectData.project_end)}</span>
          <strong className="date-description">Projekt {utils.isInFuture(projectData.project_end) ? 'skončí' : 'skončil'}</strong>
        </div>
        <div className="percents pie-chart">
          <div className={`chart ${(projectData.pragozor_percentage || 0) > 50 ? "gt-50" : ""}`}>
            <div className="ppc-progress">
              <div className="ppc-progress-fill" style={{"transform": `rotate(${360 * (projectData.pragozor_percentage || 0) / 100}deg)`}}></div>
            </div>
            <div className="ppc-percents">
              <div className="pcc-percents-wrapper">{projectData.pragozor_percentage} %</div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
