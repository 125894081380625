import { FC } from 'react';
import { Link } from 'react-router-dom';
import './subCategoryButton.scss';

export const SubCategoryButton: FC<{
  tag: string;
  projectName: string;
  active: boolean;
}> = ({ children, tag, projectName, active = false }) => {
  return (
    <Link
      to={`#${tag}`}
      className={`subCategoryButton ${active === true ? 'active' : ''}`}
      data-tag={tag}
      data-project-name={projectName}
    >
      {projectName}
    </Link>
  );
};
