import { FC, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { HomeView } from '../views/home';
import { CategoryView } from '../views/category';
import { categories } from '../api/categories';

export const Router: FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search, [
      "tracker1",
      "tracker2",
    ]);
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeView />
        }
      />
      {Object.keys(categories).map((cat, index) => {
        return (<Route
          key={index}
          path={`/${cat}`}
          element={
            <CategoryView tag={cat} category={categories[cat]} />
          }
        />)
      })}

      {/* <Route path="/*">
        <Navigate to="/" />
      </Route> */}
    </Routes>
  );
};
